import { useEffect, useLayoutEffect, useRef, useState } from "react";
import CustomBarChart from "../Stats/CustomBarChart";
import CustomLineChart from "../Stats/CustomLineChart";
import CustomPieChart from "../Stats/CustomPieChart";
import HorizontalChart from "../Stats/HorizontalChart";
import Navigation from "../Util/Navigation";
import "../../Styles/stats.scss";
import { BsImages, BsFiletypeCsv } from "react-icons/bs";
import html2canvas from "html2canvas";
import StackedAreaChart from "../Stats/StackedAreaChart";
import { FiRefreshCcw } from "react-icons/fi";
import {
  FaHelmetSafety,
  FaMoneyBillTrendUp,
  FaScroll,
  FaXmark,
} from "react-icons/fa6";
import { FaMapMarked, FaSearch } from "react-icons/fa";
// import CustomBarChart from "./CustomBarChart";
import GaugeChart from "react-gauge-chart";
import { MdAttachMoney, MdOutlineNewLabel } from "react-icons/md";



export default function Landing(props) {
   const [data, setData] = useState(null);
  const [aspect2, setAspect2] = useState(2.1);
  const refPie2 = useRef(null);

  const [aspect, setAspect] = useState(1.5);
  const [aspect1, setAspect1] = useState(1.5);

  const p1ref = useRef();
  const p2ref = useRef();
  const p3ref = useRef();
  const p4ref = useRef();

  const b1ref = useRef();
  const b2ref = useRef();

  const l1ref = useRef();
  const l2ref = useRef();

  const h1ref = useRef();
  const h2ref = useRef();
  const [unique, setUnique] = useState();
  const [unique2, setUnique2] = useState();
  const [monthly, setMonthly] = useState();
  const [reportDist, setReportDist] = useState();
  const [genderDist, setGenderDist] = useState();
  const [gbvStatus, setGBVStatus] = useState();
  const [meStatus, setMEStatus] = useState();
  const [emtGBV, setEmtGBV] = useState(null);
  const [emtME, setEmtME] = useState(null);
  const [projects, setProjects] = useState(null);
  const [totalER, setTotalER] = useState(null);
  const [totalUsers, setTotalUsers] = useState(null);
  const [totalreports, setTotalReports] = useState(null);
  const [totalgbv, setTotalgbv] = useState(null);
  const [totalme, setTotalme] = useState(null);
  const [totalRecieved, setTotalRecieved] = useState(null);
  const [recievedME, setRecievedME] = useState(null);
  const [recievedGBV, setRecievedGBV] = useState(null);

  const handleDownloadImage = async (printRef) => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };


  useEffect(() => {
    fetch(`/api/reports/monthly/2024-01-01/2025-01-01`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        const md = [];
        data.result.map((item) => {
          const obj = {};
          obj.date = item.datetime;
          obj.name =
            item.datetime.split("-")[1] == "01"
              ? "Jan"
              : item.datetime.split("-")[1] == "02"
              ? "Feb"
              : item.datetime.split("-")[1] == "03"
              ? "Mar"
              : item.datetime.split("-")[1] == "04"
              ? "Apr"
              : item.datetime.split("-")[1] == "05"
              ? "May"
              : item.datetime.split("-")[1] == "06"
              ? "Jun"
              : item.datetime.split("-")[1] == "07"
              ? "Jul"
              : item.datetime.split("-")[1] == "08"
              ? "Aug"
              : item.datetime.split("-")[1] == "09"
              ? "Sep"
              : item.datetime.split("-")[1] == "10"
              ? "Oct"
              : item.datetime.split("-")[1] == "11"
              ? "Nov"
              : "Dec";
          obj.value = item.reports_count;
          md.push(obj);
        });
        setMonthly(md);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/reports/monthly/emt/GBV/2023-01-01/2024-01-01`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        let gbvData = [];
        const unique1 = [...new Set(data.result.map((item) => item.Name))];
        setUnique(unique1);
        let d = [
          {
            name: "Jan",
          },
          {
            name: "Feb",
          },
          {
            name: "Mar",
          },
          {
            name: "Apr",
          },
          {
            name: "May",
          },
          {
            name: "Jun",
          },
          {
            name: "Jul",
          },
          {
            name: "Aug",
          },
          {
            name: "Sep",
          },
          {
            name: "Oct",
          },
          {
            name: "Nov",
          },
          {
            name: "Dec",
          },
        ];

        unique1.forEach((item) => {
          let i = data.result
            .map((e) => {
              return e.Name;
            })
            .indexOf(item);
          d[0][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "01"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[1][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "02"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[2][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "03"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[3][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "04"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[4][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "05"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[5][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "06"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[6][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "07"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[7][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "08"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[8][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "09"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[9][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "10"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[10][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "11"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[11][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "12"
              ? parseInt(data.result[i].reports_count)
              : 0;
        });
        setEmtGBV(d);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/reports/monthly/emt/ME/2023-01-01/2024-01-01`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        const unique1 = [...new Set(data.result.map((item) => item.Name))];
        setUnique2(unique1);
        let d = [
          {
            name: "Jan",
          },
          {
            name: "Feb",
          },
          {
            name: "Mar",
          },
          {
            name: "Apr",
          },
          {
            name: "May",
          },
          {
            name: "Jun",
          },
          {
            name: "Jul",
          },
          {
            name: "Aug",
          },
          {
            name: "Sep",
          },
          {
            name: "Oct",
          },
          {
            name: "Nov",
          },
          {
            name: "Dec",
          },
        ];

        unique1.forEach((item) => {
          let i = data.result
            .map((e) => {
              return e.Name;
            })
            .indexOf(item);
          d[0][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "01"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[1][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "02"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[2][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "03"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[3][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "04"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[4][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "05"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[5][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "06"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[6][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "07"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[7][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "08"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[8][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "09"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[9][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "10"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[10][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "11"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[11][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "12"
              ? parseInt(data.result[i].reports_count)
              : 0;
        });
        setEmtME(d);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/reports/type`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        const dist = [];
        data.result.map((item) => {
          const obj = {};
          obj.name = item.Type;
          obj.value = parseInt(item.count);
          dist.push(obj);
        });
        console.log(dist);
        setReportDist(dist);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/reports/gender`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        const dist = [];
        data.result.map((item) => {
          const obj = {};
          obj.name = item.Gender;
          obj.value = parseInt(item.count);
          dist.push(obj);
        });
        setGenderDist(dist);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/reports/type/count/GBV`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        const st = [];
        data.result.map((item) => {
          const obj = {};
          obj.name = item.Status;
          obj.value = parseInt(item.count);
          st.push(obj);
        });
        setGBVStatus(st);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/reports/type/count/ME`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        const st = [];
        data.result.map((item) => {
          const obj = {};
          obj.name = item.Status;
          obj.value = parseInt(item.count);
          st.push(obj);
        });
        setMEStatus(st);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/erteams/paginated/0`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setTotalER(data?.total);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/reports/paginated/0`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setTotalReports(data?.total);
        setTotalgbv(data?.gbv);
        setTotalme(data?.me);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/reports/status/Received/0`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setTotalRecieved(data?.total);
      })
      .catch((err) => {});
  }, []);

   useEffect(() => {
     fetch(`/api/reports/status/type/GBV/Received`)
       .then((response) => {
         if (response.ok) {
           return response.json();
         } else throw Error("");
       })
       .then((data) => {
          console.log(data);
               const count = data.result ? parseInt(data.result[0].count) : 0;
               setRecievedGBV(count);
       })
       .catch((err) => {});
   }, []);
  
  
   useEffect(() => {
     fetch(`/api/reports/status/type/ME/Received`)
       .then((response) => {
         if (response.ok) {
           return response.json();
         } else throw Error("");
       })
       .then((data) => {
         const count = data.result ? parseInt(data.result[0].count) : 0;
         setRecievedME(count);
       })
       .catch((err) => {});
   }, []);

  useEffect(() => {
    fetch(`/api/users/paginated/0`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setTotalUsers(data?.total);
      })
      .catch((err) => {});
  }, []);

  return (
    <div>
      <div className="stats">
        {/* <div className="top">
          <TopItem title="Total Reports" amt={totalreports} color="#C9EEFF" />
          <TopItem
            title="GBV Reports"
            amt={reportDist ? reportDist[0].value : 0}
            color="#F1DEC9"
          />
          <TopItem
            title="ME Reports"
            amt={reportDist ? reportDist[1].value : 0}
            color="#FBFFB1"
          />
          <TopItem title="New Reports" amt={totalRecieved} color="#FFFBEB" />
          <TopItem title="Users" amt={totalUsers} color="#FFA3FD" />
          <TopItem title="Respondents" amt={totalER} color="#C9F4AA" />
        </div> */}
        <div className="taskstats">
          <div className="left">
            <div className="bar">
              <div></div>
              <h2>GBV</h2>
            </div>

            <div className="outer">
              <div className="ll">
                <div className="section">
                  <div className="single">
                    <FaScroll className="ts" />
                    <div>
                      {/* <h4>{reportDist ? reportDist[0].value : 0}</h4> */}
                      <h4>{reportDist && reportDist.length > 1 ? reportDist[0].value : 0}</h4>
                      <p>Total GBV Reports</p>
                    </div>
                  </div>
                </div>
                <div className="section">
                  <div className="single">
                    <FaMapMarked className="ts" />
                    <div>
                      <h4>{recievedGBV}</h4>
                      <p>New GBV Reports</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section divrow">
                <h3>GBV</h3>
                <div className="my-gauge">
                  <GaugeChart
                    className="gauge"
                    id="gauge-chart2"
                    nrOfLevels={4}
                    colors={["#DC143C", "#191970", "#000000", "#FFD700"]}
                    arcWidth={0.3}
                    percent={
                      data
                        ? (
                            parseInt(totalreports) /
                            parseInt(reportDist ? reportDist[0].value : 0)
                          ).toFixed(2)
                        : 0
                    }
                    textColor="gray"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="left">
            <div className="bar">
              <div></div>
              <h2>ME</h2>
            </div>

            <div className="outer">
              <div className="ll">
                <div className="section">
                  <div className="single">
                    <FaMoneyBillTrendUp className="ts" />
                    <div>
                      {reportDist && reportDist.length > 1 ? reportDist[1].value : 0}
                      <p>Total Medical Reports</p>
                    </div>
                  </div>
                </div>
                <div className="section">
                  <div className="single">
                    <MdAttachMoney className="ts" />
                    <div>
                      <h4>{recievedME}</h4>
                      <p>New Medical Reports</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section divrow">
                <h3>ME</h3>
                <div className="my-gauge">
                  <GaugeChart
                    className="gauge"
                    id="gauge-chart2"
                    nrOfLevels={4}
                    colors={["#DC143C", "#191970", "#000000", "#FFD700"]}
                    arcWidth={0.3}
                    percent={
                      data
                        ? (
                            parseInt(totalreports) /
                            parseInt(reportDist ? reportDist[0].value : 0)
                          ).toFixed(2)
                        : 0
                    }
                    textColor="gray"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bar">
          <div></div>
          <h2>Reports Distribution and Status</h2>
        </div>
        <div className="pies">
          <div ref={p1ref} className="chart">
            <h3>Reports Distribution</h3>
            {reportDist && (
              <CustomPieChart
                data={reportDist}
                colors={["orange", "red"]}
                aspect={aspect}
              />
            )}
            <div className="save">
              <BsImages
                color="blue"
                onClick={() => {
                  handleDownloadImage(p1ref);
                }}
              />
              <BsFiletypeCsv
                color="blue"
                onClick={() => {
                  saveData(reportDist);
                }}
              />
            </div>
          </div>
          <div ref={p2ref} className="chart">
            <h3>GBV Status</h3>
            {/* {console.log(gbvStatus)} */}
            {gbvStatus && (
              <CustomPieChart
                data={gbvStatus}
                colors={["orange", "red", "gray", "green"]}
                aspect={aspect}
              />
            )}
            <div className="save">
              <BsImages
                color="blue"
                onClick={() => {
                  handleDownloadImage(p2ref);
                }}
              />
              <BsFiletypeCsv
                color="blue"
                onClick={() => {
                  saveData(gbvStatus);
                }}
              />
            </div>
          </div>
          <div ref={p3ref} className="chart">
            <h3>ME Status</h3>
            {meStatus && (
              <CustomPieChart
                data={meStatus}
                colors={["orange", "red", "gray", "green"]}
                aspect={aspect}
              />
            )}
            <div className="save">
              <BsImages
                color="blue"
                onClick={() => {
                  handleDownloadImage(p3ref);
                }}
              />
              <BsFiletypeCsv
                color="blue"
                onClick={() => {
                  saveData(projects?.Expense);
                }}
              />
            </div>
          </div>
        </div>
        <div className="bar">
          <div></div>
          <h2>Gender and monthly Summary</h2>
        </div>
        <div className="middle">
          <div ref={p4ref} className="chart">
            <h3>Gender Distribution</h3>
            {genderDist && (
              <CustomPieChart
                data={genderDist}
                colors={["purple", "blue"]}
                aspect={props.showing ? 1.5 : 1}
              />
            )}
            <div className="save">
              <BsImages
                color="blue"
                onClick={() => {
                  handleDownloadImage(p4ref);
                }}
              />
              <BsFiletypeCsv
                color="blue"
                onClick={() => {
                  saveData(genderDist);
                }}
              />
            </div>
          </div>
          <div ref={b1ref} className="chart">
            <h3>Monthly Summary</h3>
            {monthly && (
              <CustomBarChart
                data={monthly}
                aspect={props.showing ? 2.5 : 1.5}
              />
            )}
            <div className="save">
              <BsImages
                color="blue"
                onClick={() => {
                  handleDownloadImage(b1ref);
                }}
              />
              <BsFiletypeCsv
                color="blue"
                onClick={() => {
                  saveData(monthly);
                }}
              />
            </div>
          </div>
        </div>
        <div className="bar">
          <div></div>
          <h2>EMT Perfomance</h2>
        </div>
        <div className="bottom">
          <div ref={l1ref} className="chart">
            <h3>GBV</h3>
            {emtGBV && (
              <StackedAreaChart
                unique={unique}
                data={emtGBV}
                aspect={props.showing ? aspect2 : 1.5}
              />
            )}
            <div className="save">
              <BsImages
                color="blue"
                onClick={() => {
                  handleDownloadImage(l1ref);
                }}
              />
              <BsFiletypeCsv
                color="blue"
                onClick={() => {
                  saveData(emtGBV);
                }}
              />
            </div>
          </div>
          <div ref={l2ref} className="chart">
            <h3>ME</h3>
            {emtME && (
              <StackedAreaChart
                unique={unique2}
                data={emtME}
                aspect={props.showing ? aspect2 : 1.5}
              />
            )}
            <div className="save">
              <BsImages
                color="blue"
                onClick={() => {
                  handleDownloadImage(l2ref);
                }}
              />
              <BsFiletypeCsv
                color="blue"
                onClick={() => {
                  saveData(emtME);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const TopItem = (props) => {
  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div
      style={{ backgroundColor: props.color ?? props.color }}
      className="tp_item"
    >
      <p>{props.title}</p>
      <h1>{withCommas(props.amt)}</h1>
    </div>
  );
};
